// const zsqyUrl = 'http://81.69.44.138:8033'
const zsqyUrl = 'http://183.94.86.131:8033'
// const zsqyUrl = 'http://10.120.44.83:8033'
// const zsqyUrl = process.env.NODE_ENV === 'production'
//   ? `http://${window.location.hostname}:8033`
//   : 'http://113.57.111.48:81/ylc'
// const zsqyUrl = 'http://192.168.1.15:8033/'e1dc5a5
export default {
  zsqyUrl
}
