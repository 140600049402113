import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import file from './components'
// ant-design-vue
import antDesignVue from 'ant-design-vue'
import './style/theme.less'
import imageUpload from '@/components/ax-image-modal/src/image-upload'
// 引入echart
import * as echarts from 'echarts'
import BaiduMap from 'vue-baidu-map'
import VueAMap from 'vue-amap'
// antdvLibs
import antdvLibs from 'antdv-libs'
import 'antdv-libs/lib/AntdvLibs.css'
import '@/style/common/font.css'
import config from './common/config'

// 引入字体样式
import "@/assets/font/font.css"

// iconfont库
import './assets/iconfont/iconfont.css'

// 组织机构树
import VOrgTree from 'v-org-tree'
import 'v-org-tree/dist/v-org-tree.css'

import './components/components'
import filters from './common/filters'

import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import VueAnimateNumber from 'vue-animate-number'
import '../flexible.js'
import * as apis from './apis'
// 一些使用频率较高的方法挂载到原型上，便于调用
import * as common from './common'

import 'element-ui/lib/theme-chalk/index.css'
import ElementUI from 'element-ui';
import constant from './frontdesk/utils/constant'
Vue.use(ElementUI)
Vue.use(file, { settings: config })
Vue.use(VueAnimateNumber)

// 树形table
Vue.use(VOrgTree)

Vue.prototype.$apis = apis
Vue.prototype.$echarts = echarts

Vue.prototype.$constant = constant
// 公用方法
const bus = new Vue()
Vue.prototype.$bus = bus

Vue.use(antDesignVue)
Vue.use(antdvLibs, { settings: config })
Vue.use(BaiduMap, {
  ak: 's6nxRPqOqpzYzD89bMPKS718fXmKzKLN'
})
// Vue.directive('title', {
//   inserted: function (el, binding) {
//     document.title = el.dataset.title
//   }
// })


window._AMapSecurityConfig = {
  securityJsCode: 'e03ea51a078232163e4416a3edc5fd7b'
}

Vue.use(VueQuillEditor)
Vue.prototype.$common = common
Vue.config.productionTip = false
Vue.component(imageUpload.name, imageUpload)
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]) // 插入过滤器名和对应方法
})
Vue.prototype.$totalAmountChinese = filters.convertCurrency

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
