import Vue from 'vue'
import UploadModal from './UploadModal.vue'

const UploadModalConstructor = Vue.extend(UploadModal)

let uploadModalInstance = null

const vmCreate = (options = {}, resolve) => {
  uploadModalInstance = new UploadModalConstructor({
    propsData: options,
    mounted () {
      this.visible = true
      this.$on('confirm', data => {
        this.visible = false
        uploadModalInstance = null
        resolve(data)
      })
      this.$on('cancel', data => {
        this.visible = false
        uploadModalInstance = null
      })
    }
  }).$mount()
}

const uploadModal = options => {
  return new Promise(resolve => {
    if (!uploadModalInstance) {
      vmCreate(options, resolve)
    }
  })
}

export default uploadModal
