import Vue from 'vue'
import VueRouter from 'vue-router'
import cookieUtil from '../common/cookie-util'
import frontdesklayout from '@/frontdesklayout'
import store from '../store'
import layoutView from '@/pages/main/layout-view/index.vue'



const pipe = x => () => import(`@/frontdesk/${x}`)

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  routes: [{
    path: '/login',
    meta: {
      title: '登录页',
      hideInMenu: true
    },
    component: () => import('@/pages/login')
  },
  {
    path: '/userInfo',
    meta: {
      title: '个人信息',
      icon: 'home'
    },
    component: layoutView,
    children: [{
      path: '/userInfo',
      meta: {
        hideInMenu: true,
        title: '个人信息'
      },
      component: () => import('@/pages/userInfo')
    }]
  },
  {
    path: '/visualization',
    component: frontdesklayout,
    meta: {
      title: '可视页',
      hideInMenu: true
    },
    children: [
      {
        path: 'index',
        name: 'index',
        component: pipe('visualization'),
        meta: {
          title: '可视页面'
        }
      },
      {
        path: 'diagram',
        name: 'diagram',
        component: pipe('menu/diagram'),
        meta: {
          title: '图列信息'
        }
      },
      {
        path: 'monitoring',
        name: 'monitoring',
        component: pipe('menu/monitoring'),
        meta: {
          title: '监控设备'
        }
      },
      {
        path: 'patrol',
        name: 'patrol',
        component: pipe('menu/patrol'),
        meta: {
          title: '巡护管理'
        }
      },
      {
        path: 'environment',
        name: 'environment',
        component: pipe('menu/environment'),
        meta: {
          title: '环境监测'
        }
      },
      {
        path: 'warning',
        name: 'warning',
        component: pipe('menu/warning'),
        meta: {
          title: '预警统计'
        }
      },
      {
        path: 'warningdetails/:id/:pagSum',
        name: 'warningdetails',
        component: pipe('menu/warningdetails'),
        meta: {
          title: '预警详情'
        }
      },
      {
        path: 'police',
        name: 'police',
        component: pipe('menu/warningpolice'),
        meta: {
          title: '设备报警'
        }
      },
      {
        path: 'propaganda',
        name: 'propaganda',
        component: pipe('menu/propaganda'),
        meta: {
          title: '宣传片'
        }
      }

    ]

  }
]
})

router.beforeEach((to, from, next) => {
  const token = cookieUtil.getToken()
  if (token) {
    store.dispatch('app/getMenuList')
  }
  if (!token && to.path !== '/login' && !to.meta.whiteRoute) {
    next('/login')
  } else if (token && to.path === '/login') {
    store.dispatch('app/getMenuList')
   
  }else if (token && to.path === '/') {
    next('/enterpriseService/serviceManagement')
   
  } else {
    next()
  }
})

export default router
