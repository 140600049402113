<template>
  <div class="header-right-info">
    <!-- <div class="msg">
      <a-dropdown>
        <a class="ant-dropdown-link" @click="e => e.preventDefault()">
          <a-badge :count="count.totalNumber" class="user-avatar">
            <ax-icon size="18" type="bell" />
          </a-badge>
        </a>
        <a-menu slot="overlay">
          <a-menu-item>
            <a-badge :count="count.waitNumber" class="user-avatar" :offset="[-2, 0]">
              <a @click="handleTask" style="padding: 0 10px;">
                代办任务
              </a>
            </a-badge>
          </a-menu-item>
          <a-menu-item>
            <a-badge :count="count.noticeNumber" class="user-avatar" :offset="[-2, 0]">
              <a @click="handleMsg" style="padding: 0 10px;">
                我的公告 
              </a>
            </a-badge>
          </a-menu-item>
         
          <a-menu-item>
            <a-badge :count="count.emailNumber" class="user-avatar" :offset="[-2, 0]">
              <a @click="handlEmail" style="padding: 0 10px;">
               我的邮件
              </a>
            </a-badge>
          </a-menu-item>
        </a-menu>
      </a-dropdown>
    </div> -->
    <a-dropdown>

      <div class="user-style">

        <ax-icon type="icon-yonghu" size="18" style="margin-right: 10px;" />
        欢迎您，<span style="color:#3753DB;cursor: pointer;">{{userInfo && userInfo.realname}}</span>
        <ax-icon type="icon-a-xiala" size="12" style="margin-left: 6px;" />

      </div>
      <a-menu slot="overlay" @click="handleClick">
        <template v-for="(action, index) in actionList">
          <a-menu-item :key="action.type">
            {{action.text}}
          </a-menu-item>
          <a-menu-divider :key="index" v-if="action.divider" />
        </template>
      </a-menu>

    </a-dropdown>
  </div>

</template>

<script>
import cookieUtil from '@/common/cookie-util'
import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {
  name: 'HeaderRightInfo',
  data () {
    return {
      actionList: [
        {
          type: 'userInfo',
          text: '个人信息',
          icon: '',
          showPlain: true,
          divider: false
        },
        {
          type: 'logout',
          text: '退出登录',
          icon: 'logout',
          showPlain: true,
          divider: false
        }
      ]
    }
  },
  computed: {
    ...mapGetters('app', ['userInfo', 'count'])
  },
  created () {
    // this.getInfoList()
  },
  methods: {
    ...mapMutations('app', ['setTagList']),
    ...mapActions('app', ['getCount']),

    handleClick ({ key }) {
      if (key === 'logout') {
        this.$apis.components.logout().then(res => {
          this.setTagList([])
          cookieUtil.removeToken()
          location.reload()
        }).catch(e => {
          this.$message.error({ content: '操作失败!', key, duration: 2 })
        })
        return
      }
      this.$router.push({ path: '/userInfo', query: { id: this.userInfo.id } })
    },
    handleMsg () {
      this.$router.push({ path: '/officeModule/notice/list' })
    },
    handleTask () {
      this.$router.push({ path: '/task/current' })
    },
    handlEmail(){
      this.$router.push({ path: '/officeModule/mail/mailList/index' })
    },
    getInfoList () {
      this.getCount()
    }
  }
}
</script>

<style lang="less" scoped>
.header-right-info {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .msg {
    margin-right: 28px;
  }
  .user-style {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    .plain-action {
      margin-left: 16px;
    }
  }
}
/deep/ .ant-dropdown-open span {
  text-align: center;
}
</style>
