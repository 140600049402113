const item = {
    namespaced: true,
    state: {
      meetUserinfo:{},
      FileMsg:{},
      latlog:[],
      mapMsg:{}
    },
    getters: {
    },
    mutations: {
      getmeetUserinfo (state, info) {
        state.meetUserinfo = info
      },
      getFileMsg (state, info) {
        state.FileMsg = info
      },
      getLatlog (state, info) {
        state.latlog = info
      },
      getMapmsg (state, info) {
        state.mapMsg = info
      },
  
    },
    actions: {

      }
    }
  
  
  export default item
  