import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import app from './modules/app'
import zsqy from './modules/zsqy'
import assets from './modules/assets'
import item from './modules/item'
import project from './modules/project'
import community  from './modules/community'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app,
    zsqy,
    assets,
    item,
    project,
    community
  },
  plugins: [createPersistedState({})]
})

export default store
