import config from 'common/config'
import { get } from 'common/request'
import { getAsyncRoutes } from 'common/tools'
import router from '../../router'
import routes from '../../router/routes'
import cookieUtil from '../../common/cookie-util'
const app = {
  namespaced: true,
  state: {
   menu:[],
   meunMsg:{}
  },
  mutations: {
    updateMenu (state, menu) {
      state.menu = menu
    },
    updatemeunMsg(state, meunMsg) {
      state.meunMsg = meunMsg
    },
  },
  actions: {
  }
}

export default app
