import axConfig from '../common/config'
import * as utils from '../common/utils'

/**
 * 设置动态名称的全局过滤器
 * @param dynamicFilters
 * @returns {{name: string, fn: (function(*=, *, ...[*]=): *)}}
 */
const globalFilter = (dynamicFilters = {}) => {
  return {
    name: 'globalFilter',
    fn: (value, filterName, args) => {
      return dynamicFilters[filterName]?.(value, args) || value
    }
  }
}

const install = (Vue, options = {}) => {
  const _options = Object.assign({ publicStyle: true }, options)

  if (_options.publicStyle) require('./style/index.less')

  // 合并配置
  if (_options.settings) Object.assign(axConfig, _options.settings)

  const el = document.createElement('DIV')
  document.body.appendChild(el)

  const ax = new Vue({
    data: {
      config: axConfig
    },
    render: h => h()
  }).$mount(el)

  Object.keys(utils).forEach(key => {
    ax[key] = utils[key]
  })

  // 设置动态名称的全局过滤器
  const dynamicFilters = ax.config.dynamicFilters
  Vue.filter(globalFilter(dynamicFilters).name, globalFilter(dynamicFilters).fn)

  Vue.prototype.$ax = ax
}

export { install }
