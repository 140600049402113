import UploadModal from './src/UploadModal.vue'
import instance from './src/upload-modal'
import './src/index.less'

UploadModal.install = Vue => {
  Vue.component(UploadModal.name, UploadModal)
}

UploadModal.instance = instance

export default UploadModal
