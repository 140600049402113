/**
 * 格式化form的数据
 * @param formData
 * @param formConfig
 * @returns {{formValues: {}, formConfig: {layout: string, labelAlign: string, colon: boolean, labelWidth: number}, list: {options: {gutter: number}, type: string, cols: []}[]}}
 */
const initGridFormData = (formData = [], formConfig = {}) => {
  const formBuilder = {
    formConfig: {
      layout: 'horizontal',
      labelAlign: 'right',
      labelWidth: 125,
      colon: true,
      ...formConfig
    },
    list: [{
      type: 'grid',
      options: { gutter: 24 },
      cols: []
    }],
    formValues: {}
  }
  formBuilder.list[0].cols = formData.map(item => {
    const { options = {}, col = {} } = item
    return {
      span: 6,
      ...formConfig.col,
      ...col,
      list: [
        {
          type: 'input',
          ...item,
          options: {
            width: '100%',
            disabled: formConfig.disabled,
            allowClear: true,
            ...options
          }
        }
      ]
    }
  })
  return formBuilder
}
/**
 * 随机生成Guid
 * @returns {string}
 */
const getGuid = () => {
  const s = []
  const hexDigits = '0123456789abcdef'
  for (let i = 0; i < 32; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
  }
  s[14] = '' // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1) // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23]
  return s.join('')
}
/**
 * 设备二进制文件
 * @param data
 * @param fileName
 * @param type
 */
const blobDownloads = (data, fileName, type = 'application/octet-stream') => {
  console.log(data)
  if (!data) return
  const url = window.URL.createObjectURL(new Blob([data.data], { type }))
  const link = document.createElement('a')
  link.style.display = 'none'
  link.href = url
  // setAttribute:为指定元素添加指定的属性，并为其赋指定的值
  link.setAttribute('download', fileName)

  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)// 下载完毕删除a标签
  window.URL.revokeObjectURL(url)
}

/**
 * 参数判空
 * @param paramsKey
 * @param paramsValue
 * @returns {boolean}
 */
const isParamsEmpty = (paramsKey, paramsValue) => {
  if (!paramsValue) {
    console.error(`${paramsKey}为空，无法向下执行，请检查代码或相关配置！`)
    return true
  }
}

const pipe = x => () => import(`@/pages/${x}`)
/**
 * 递归处理返回的菜单项
 * @param asyncMenuList
 * @returns {Array}
 */
const getAsyncRoutes = (asyncMenuList = []) => {
  asyncMenuList.forEach(item => {
    item.component = pipe(item.component)
    if (item.children?.length > 0) {
      getAsyncRoutes(item.children)
    }
  })
  return asyncMenuList
}
/**
 * 过滤隐藏的菜单(暂时没用)
 * @param menuList
 * @returns {unknown[]}
 */
const filterHiddenMenu = (menuList = []) => {
  return (menuList.map(menu => {
    if (!menu.meta.hideInMenu) {
      if (menu?.children?.length > 0) menu.children = filterHiddenMenu(menu.children)
      return menu
    }
  })).filter(item => item)
}
/**
 * 值判空
 * @param value
 * @returns {boolean}
 */
const isEmpty = value => {
  return value === '' || value === undefined || value === null
}
// 设置属性
const setAttribute = (obj = [], prop, options, attribute) => {
  if (typeof prop !== 'object') {
    prop = Array(prop)
  }
  obj.forEach(res => {
    if (Object.prototype.hasOwnProperty.call(res, 'childrenNodes')) {
      setAttribute(res.childrenNodes, prop, options, attribute)
    } else {
      if (prop.includes(res?.model)) {
        res.options[options] = attribute
      }
    }
  })
}

// 设置属性
const setAttributeRules = (obj = [], prop, options, attribute) => {
  if (typeof prop !== 'object') {
    prop = Array(prop)
  }
  obj.forEach(res => {
    if (Object.prototype.hasOwnProperty.call(res, 'childrenNodes')) {
      setAttributeRules(res.childrenNodes, prop, options, attribute)
    } else {
      if (prop.includes(res?.model)) {
        res.rules[0][options] = attribute
      }
    }
  })
}

// 循环数据必填
const ifmust = (data, arr, arrs, arrtext) => {
  const _this = data
  let ifs = true
  for (let i = 0; i < arr.length; i++) {
    for (let j = 0; j < arrs.length; j++) {
      if (!isEmpty(arr[i][arrs[j]])) {
      } else {
        const info = `第${i + 1}条${arrtext[j]}未填`
        _this.$message.info(info)
        ifs = false
        return ifs
      }
    }
  }
  return ifs
}
const convertCurrency = (money) => {
  console.log('money:' + money)
  if (money === 0) {
    return '零'
  }
  if (money === '' || money === undefined || money === undefined) {
    return '零'
  }
  let num = parseFloat(money)
  if (!/^(0|[1-9]\d*)(\.\d+)?$/.test(num)) {
    return '零' // 判断数据是否大于0
  }
  var unit = '千百拾亿千百拾万千百拾元角分'
  var str = ''
  num += '00'
  var indexpoint = num.indexOf('.') // 如果是小数，截取小数点前面的位数
  if (indexpoint >= 0) {
    num = num.substring(0, indexpoint) + num.substr(indexpoint + 1, 2) // 若为小数，截取需要使用的unit单位
  }
  unit = unit.substr(unit.length - num.length) // 若为整数，截取需要使用的unit单位
  for (var i = 0; i < num.length; i++) {
    str += '零壹贰叁肆伍陆柒捌玖'.charAt(num.charAt(i)) + unit.charAt(i) // 遍历转化为大写的数字
  }
  const str2 = str
    .replace(/零(千|百|拾|角)/g, '零')
    .replace(/(零)+/g, '零')
    .replace(/零(万|亿|元)/g, '$1')
    .replace(/(亿)万|壹(拾)/g, '$1$2')
    .replace(/^元零?|零分/g, '')
    .replace(/元$/g, '元整') // 替换掉数字里面的零字符，得到结果
  console.log(str2)
  // 赋值
  return str2
}
const currentTime = (num, time) => {
  const nowDate = new Date()
  const date = {
    year: nowDate.getFullYear(),
    month: nowDate.getMonth() + 1,
    date: nowDate.getDate()
  }
  if (parseInt(date.month) < 10) {
    date.month = '0' + date.month
  }

  if (parseInt(date.date) < 10) {
    date.date = '0' + date.date
  }
  var currentDate = date.year + '-' + date.month + '-' + date.date
  return currentDate
}

const getTime = (num, time = 0) => {
  const data = new Date(num).getTime() + time
  const nowDate = new Date(data)
  const date = {
    year: nowDate.getFullYear(),
    month: nowDate.getMonth() + 1,
    date: nowDate.getDate()
  }
  if (parseInt(date.month) < 10) {
    date.month = '0' + date.month
  }

  if (parseInt(date.date) < 10) {
    date.date = '0' + date.date
  }
  var currentDate = date.year + '-' + date.month + '-' + date.date
  return currentDate
}

const getBeforeDate = (num, time) => {
  const n = -num
  let d = ''
  if (time) {
    d = new Date(time)
  } else {
    d = new Date()
  }
  let year = d.getFullYear()
  let mon = d.getMonth() + 1
  let day = d.getDate()
  if (day <= n) {
    if (mon > 1) {
      mon = mon - 1
    } else {
      year = year - 1
      mon = 12
    }
  }
  d.setDate(d.getDate() - n)
  year = d.getFullYear()
  mon = d.getMonth() + 1
  day = d.getDate()
  const s = year + '-' + (mon < 10 ? ('0' + mon) : mon) + '-' + (day < 10 ? ('0' + day) : day)
  console.log(s)
  return s
}

const cutOutString = (string) => {
  if (typeof string === 'string' && string.length > 5) {
    return string.slice(0, 5) + '...'
  }
  return string
}

const openNewWindow = (url, title) => {
  const win = window.open('about:blank')
  win.document.title = title
  const iframe = document.createElement('iframe')
  iframe.src = url
  iframe.style.width = '100%'
  iframe.style.height = '100vh'
  iframe.style.margin = '0'
  iframe.style.padding = '0'
  iframe.style.overflow = 'hidden'
  iframe.style.border = 'none'
  win.document.body.style.margin = '0'
  win.document.body.appendChild(iframe)
}

export {
  setAttribute,
  ifmust,
  isEmpty,
  getGuid,
  initGridFormData,
  blobDownloads,
  isParamsEmpty,
  getAsyncRoutes,
  filterHiddenMenu,
  setAttributeRules,
  convertCurrency,
  getBeforeDate,
  cutOutString,
  currentTime,
  openNewWindow,
  getTime
}
