<template>
  <div class="clearfix">
    <a-upload
      :action="`${baseUrl}/file/upload`"
      list-type="picture-card"
      :data='uploadData'
      accept='.jpg,.png,.jpeg'
      :file-list="fileList"
      :headers="headers"
      :showAccept='true'
      :disabled='showImage'
      @preview="handlePreview"
      @change="handleChange"
      :remove="remove"
    >
      <div v-if="fileList.length < (this.size===3?this.size:'8') ">
        <a-icon type="plus" />
        <div class="ant-upload-text">
          上传图片
        </div>
      </div>
    </a-upload>
    <a-modal
      :visible="previewVisible"
      :footer="null"
      @cancel="handleCancel"
    >
      <img
        alt="example"
        style="width: 100%"
        :src="previewImage"
      />
    </a-modal>
  </div>
</template>
<script>
import cookieUtil from '@/common/cookie-util'
import api from './api'
export default {
  name: 'iamgeUpload',
  props: ['bizId', 'disabled', 'size'],
  data () {
    return {
      api,
      previewVisible: false,
      previewImage: '',
      showImage: false,
      uploadData: {
        
        bizId: '',
        bizType: '1',
        id: ''
      },
      baseUrl:'http://112.30.60.71:1089/ylc',
      // baseUrl: process.env.VUE_APP_BASE_URL,
      fileList: [],
      headers: {
        authorization: cookieUtil.getToken()
      }
    }
  },
  watch: {
    bizId: {
      handler (val) {
        this.uploadData.bizId = val
        this.getFileList(val)
      },
      deep: true,
      immediate: true
    }
  },
  created () {
    // 分类
    this.uploadData.bizId = this.bizId
    this.showImage = this.disabled
  },
  methods: {
    /**
 * 获取附件
 */
    async getFileList (bizId) {
      api.getByBiz(bizId).then(res => {
        const list = res.data
        // console.log(list,'图片列表');
        this.fileList = list.map(res => { return { uid: res.id, name: res.fileName, url: res.url } })
      })
    },
    getBase64 (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    handleCancel () {
      this.previewVisible = false
    },
    async handlePreview (file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      // 点击眼睛显示图片
      this.previewVisible = true
    },
    async remove (file) {
      api.del(file.uid)
    },
    handleChange ({ file, fileList }) {
      this.fileList = fileList
      if (file.response) {
        this.getFileList(this.bizId)
        const { status, message } = file.response
        this.loading = false
        if (status !== 200) return this.$message.error(message || '上传失败')
      }
    },
    getFile () {
      return this.fileList
    }
  }
}
</script>
