<template>
  <div class="title">
    <div class="line"
      v-if="showLine"></div>
    <span class="text" :style="{ fontSize: fontSize + 'px' }">{{ text }}</span>
    <div class="line"
      v-if="showLine"></div>
  </div>
</template>

<script>
export default {
  name: 'TextCenter',
  props: {
    text: {
      type: String,
      default: '文本'
    },
    showLine: {
      type: Boolean,
      default: true
    },
    fontSize: {
      type: Number,
      default: 25
    }
  }
}
</script>

<style lang="less" scoped>
.title {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 30px;
  .text {
    padding: 0 20px;
    position: relative;
    box-sizing: border-box;
  }
  .line {
    width: 200px;
    height: 1px;
    background: #ccc;
  }
}
</style>
