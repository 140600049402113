import axios from 'axios'
import {
  message as antMessage
} from 'ant-design-vue'
import cookieUtil from './cookie-util'
import {
  blobDownloads
} from './tools'
import router from '../router/index'
import constant from '../frontdesk/utils/constant'

const axiosInstance = axios.create({

  //  baseURL:'http://192.168.1.118:81/ylc/',
  // baseURL:'http://192.168.1.119:1099',//袁翔
  //  baseURL:'http://192.168.1.107:1099',//李浩台式
  //  baseURL:'http://192.168.1.111:1099',//李浩笔记本
  //  baseURL:'http://192.168.1.116:1099', //吴俊
      // baseURL:'http://192.168.1.100:1099', //张显
  // baseURL:'http://192.168.1.118:1099',//线上
  baseURL:'https://estore.hbgdsz.com/enterprise/',
  timeout: 0
})

axiosInstance.interceptors.request.use(config => {
  config.headers.Authorization = cookieUtil.getToken()
  return config
}, error => {
  console.log(error)
})

const pipe = res => {
  return new Promise((resolve, reject) => res
    .then(data => {
      const {
        status,
        message
      } = data.data
      if (status === 200) resolve(data.data)
      else if (status === 400) {
        cookieUtil.removeToken()
        antMessage.error(message)
        router.push({
          path: '/login'
        })
      } else {
        antMessage.error(message || '请求失败')
        reject(data.data)
      }
    }).catch(e => {
      let errorMessage = '请求失败'
      if (e.toString().includes('timeout of')) {
        errorMessage = '请求超时'
      } else if (e.toString().includes('Network Error')) {
        errorMessage = '服务器未响应,请检查网络'
      }
      antMessage.error(errorMessage)
      reject(e)
    })
  )
}

const post = (url, data) => {
  return pipe(axiosInstance({
    url: url,
    method: 'post',
    data: data
  }))
}

const get = (url, params) => {
  return pipe(axiosInstance({
    url: url,
    method: 'get',
    params: params
  }))
}
const deletes = (url, params) => {
  return pipe(axiosInstance({
    url: url,
    method: 'delete',
    params: params
  }))
}
const zsqyget = (url, params) => {
  return pipe(axiosInstance({
    url: constant.zsqyUrl + '/zsqy' + url,
    method: 'get',
    params: params,
    headers: {
      database: '11NatureReserves'
    }
  }))
}
const zsqypost = (url, data) => {
  return pipe(axiosInstance({
    url: constant.zsqyUrl + '/zsqy' + url,
    method: 'post',
    data: data,
    headers: {
      database: '11NatureReserves'
    }
  }))
}
const zsqypostNoPrefix = (url, data) => {
  return pipe(axiosInstance({
    url: constant.zsqyUrl + url,
    method: 'post',
    data: data,
    headers: {
      database: '11NatureReserves'
    }
  }))
}
const zsqydeletes = (url, params) => {
  return pipe(axiosInstance({
    url: url,
    method: 'delete',
    params: params,
    headers: {
      database: '11NatureReserves'
    }
  }))
}

const downFile = (url, args = {}, method = 'get') => {
  const requestParams = {
    url: url,
    method: method,
    responseType: 'blob'
  }
  const paramsKey = method === 'get' ? 'params' : 'data'
  requestParams[paramsKey] = args.params
  axiosInstance(requestParams).then(res => {
    blobDownloads(res, args.fileName)
  })
}

export {
  axiosInstance,
  axios,
  post,
  get,
  zsqyget,
  zsqypost,
  zsqypostNoPrefix,
  zsqydeletes,
  deletes,
  downFile
}
