<template>
  <section class="app-main" ref="appmain">
<!-- <transition name="fade-transform" mode="out-in"> -->
    <router-view />
<!-- </transition> -->
  </section>
</template>

<script>
export default {
  name: 'AppMain',
  data () {
    return {
      height: ''
    }
  },
  computed: {

  },
  methods: {
    getHeight () {
      this.height = window.innerHeight + 'px'
      this.$refs.appmain.style.height = this.height
    }
  },
  mounted () {
    window.addEventListener('resize', this.getHeight) // 注册监听器
    this.getHeight() // 页面创建时先调用一次
  }

}
</script>

<style scoped>
</style>

<style lang="less">
.app-main{
  width: 100%;
}
</style>
