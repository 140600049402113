import { isParamsEmpty, initGridFormData } from './tools'
import { getTableLabels, getDataSource, getTaskPara } from './utils'

/**
 * 生成form表单
 * @param tableName
 * @param formConfig
 * @param taskToDo
 * @param isTask
 * @param systemName
 * @returns {Promise<*|[]|boolean>}
 */
const getForm = async (tableName, formConfig, taskToDo, isTask, systemName) => {
  const tableLabels = await getTableLabels(tableName, 1, systemName)
  if (isParamsEmpty('tableLabels', tableLabels)) return false
  let hideParams = {}; let disabledParams = {}; let requiredParams = {}
  if (taskToDo) {
    const taskPara = await getTaskPara(taskToDo, systemName)
    hideParams = isTask ? setProperty(tableName, taskPara, 'hideValue') : hideParams
    disabledParams = isTask ? setProperty(tableName, taskPara, 'fieldValid') : disabledParams
    requiredParams = setProperty(tableName, taskPara, 'needValue') || requiredParams
  }
  return initFormData(tableLabels, formConfig, hideParams, disabledParams, requiredParams, systemName)
}

/**
 * 设置form的label属性，如：hide，disabled
 * @param tableName
 * @param taskPara
 * @param key
 * @returns {boolean}
 */
const setProperty = (tableName, taskPara, key) => {
  const params = {}
  if (!taskPara[key]) return params
  taskPara[key].split(/[{ }\s+]/).forEach(item => {
    if (!item) return false
    const labelParams = item.split(/[, ， 、 ; \s+]/)
    if (tableName === labelParams[0]) {
      labelParams.splice(0, 1)
      if (key === 'needValue') {
        labelParams.forEach(labelParam => {
          params[labelParam.split('=')[0]] = {
            required: true,
            message: `${labelParam.split('=')[1]}不能为空`
          }
        })
      } else {
        labelParams.forEach(labelParam => {
          params[labelParam] = true
        })
      }
    }
  })
  return params
}

/**
 * 初始化formData
 * @param formData
 * @param formConfig
 * @param hideParams
 * @param disabledParams
 * @param requiredParams
 * @param systemName
 * @returns {Promise<{formValues: {}, formConfig: {layout: string, labelAlign: string, colon: boolean, labelWidth: number}, list: {options: {gutter: number}, type: string, cols: *[]}[]}>}
 */
const initFormData = async (formData, formConfig, hideParams = {}, disabledParams = {}, requiredParams = {}, systemName) => {
  return initGridFormData((await Promise.all(formData.map(async formItem => {
    const { colName, colDataSource, colLabel, colType, colLength, txtRow, placeholder } = formItem
    const hide = hideParams[colName] || false
    const disabled = Object.keys(disabledParams).length > 0 ? !disabledParams[colName] : false
    const requiredRule = requiredParams[colName]
    const rules = requiredRule ? getRules(formItem).concat(requiredRule) : getRules(formItem)
    const options = await getDataSource(colDataSource, systemName)
    if (!hide && colType !== 'empty') {
      return {
        label: colLabel,
        type: colType,
        model: colName,
        options: {
          options: options,
          disabled: disabled,
          maxLength: Number(colLength) || undefined,
          minRows: Number(txtRow) || 3,
          maxRows: Number(txtRow) || 3,
          placeholder: placeholder
        },
        rules: rules,
        col: {
          span: formItem.rowCol
        }
      }
    }
  }))).filter(item => item), formConfig)
}
/**
 * 生成验证规则
 * @param formItem
 * @returns {boolean|[]}
 */
const getRules = (formItem) => {
  const rules = []
  if (formItem.colRequired !== 'Y' && !formItem.colRegexp) return rules
  if (formItem.colRequired === 'Y') {
    rules.push({
      required: true,
      message: `${formItem.colLabel}不能为空`
    })
  }
  if (formItem.colRegexp) {
    const regexpArr = formItem.colRegexp.split(';')
    regexpArr.forEach(regexp => {
      const pattern = new RegExp(regexp.split('=')[0])
      const message = regexp.split('=')[1]
      rules.push({
        pattern: pattern,
        message: message
      })
    })
  }
  return rules
}

export {
  getForm
}
