
const assets = {
  namespaced: true,
  state: {
    assetsCategory: '0',
    type: ''
  },
  getters: {
    assetsCategory: state => state.assetsCategory,
    type: state => state.type
  },
  mutations: {
    setAssetsCategory (state, assetsCategory) {
      state.assetsCategory = assetsCategory || '0'
      console.log(assetsCategory, 'assetsCategory')
    },
    setType (state, type) {
      state.type = type
    }
  },
  actions: {
  }
}

export default assets
