<template>
  <div class="total-slot">
    <div>合计: &nbsp; (大写) 人民币 &nbsp; <span
        style="font-size:16px;font-weight:bold;">{{Number(summation) | convertCurrency}}</span>
    </div>
    <div class="count-end">(小写) &nbsp; ￥ {{summation || 0}} 元</div>
  </div>
</template>

<script>
export default {
  name: 'TotalCount',
  props: ['summation']
}
</script>

<style lang="less" scoped>
.total-slot {
  padding: 10px 50px;
  width: 100%;
  height: 100%;
  line-height: 32px;
  display: flex;
  justify-content: space-between;
  .count-end {
    margin-right: 20px;
  }
}
</style>
