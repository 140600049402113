<template>
  <a-table :columns="columns"
    :data-source="data"
    rowKey="id"
    :pagination="false"
    bordered>
    <template v-for="col in writeAbleAttrs"
      :slot="col"
      slot-scope="text, record">
      <div :key="col">
        <a-input style="margin: -5px 0"
          :value="text"
          @change="e => handleChange(e.target.value, record.rowKey, col)" />
      </div>
    </template>
    <template #subtotal="text, record">
      <slot name="passSubtotal"
        :record="record"></slot>
    </template>
    <template slot="operation"
      slot-scope="text, record, index">
      <div class="editable-row-operations">
        <span>
          <a
          v-if="(data.length - 1) === Number(index)"
            @click="() => AddRow(record.rowKey)">添加</a>
          <a-popconfirm title="您确定删除吗?"
            v-if="data.length > 1"
            @confirm="() => removeRow(record.rowKey)">
            <a>删除</a>
          </a-popconfirm>
        </span>
      </div>
    </template>
  </a-table>
</template>
<script>

export default {
  name: 'CopyAbleTable',
  props: {
    writeAbleAttrs: {
      type: Array,
      default: () => []
    },
    columns: {
      type: Array,
      default: () => []
    },
    tableItem: {
      type: Array,
      default: () => []
    },
    tableItemRequire: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    tableItem (val) {
      this.data = val
      this.save()
    }
  },
  data () {
    return {
      data: this.tableItem
    }
  },
  methods: {
    handleChange (value, rowKey, column) {
      const newData = [...this.data]
      const target = newData.filter(item => rowKey === item.rowKey)[0]
      if (target) {
        target[column] = value
        this.data = newData
        this.save()
      }
    },
    AddRow (rowKey) {
      const target = this.data.filter(item => rowKey === item.rowKey)[0]
      const require = this.tableItemRequire.filter(item => !target[item]).length
      if (require > 0) return this.$message.warning('请填写完整信息')
      this.$emit('AddRow')
    },
    edit (rowKey) {
      const newData = [...this.data]
      const target = newData.filter(item => rowKey === item.rowKey)[0]
      if (target) {
        this.data = newData
      }
    },
    save () {
      this.$emit('passTableData', this.data)
    },
    cancel (rowKey) {
      const newData = [...this.data]
      const target = newData.filter(item => rowKey === item.rowKey)[0]
      if (target) {
        this.data = newData
      }
    },
    removeRow (rowKey) {
      this.$emit('removeRow', rowKey)
    }
  }
}
</script>
<style lang="less" scoped>
.editable-row-operations a {
  margin-right: 8px;
}
/deep/.ant-table-thead > tr > th {
  text-align: center;
}
</style>
