<template>
  <div class="exceptions-page">
    <img :src="exceptionsImages[`page${$route.meta.exception}`]">
    <div class="back">
      <span @click="back('home')">返回首页</span>
      <span @click="back">返回上一页({{second}}s)</span>
    </div>
  </div>
</template>

<script>
import page404 from '@/assets/images/page404.jpg'
import page403 from '@/assets/images/page403.jpg'
import page500 from '@/assets/images/page500.jpg'
export default {
  name: 'Exceptions',
  data () {
    return {
      exceptionsImages: {
        page404,
        page403,
        page500
      },
      second: 5,
      timer: null
    }
  },
  methods: {
    back (type) {
      if (type === 'home') {
        this.$router.replace(this.$ax.config.homePath)
      } else {
        this.$router.back()
      }
    }
  },
  mounted () {
    this.timer = setInterval(() => {
      if (this.second === 0) this.back()
      else this.second--
    }, 1000)
  },
  beforeDestroy () {
    clearInterval(this.timer)
  }
}
</script>

<style lang="less" scoped>
  .exceptions-page{
    text-align: center;
    .back{
      margin-top: -50px;
      color: #3853db;
      font-size: 16px;
      span{
        cursor: pointer;
        &:nth-child(1){
          margin-right: 40px;
        }
      }
    }
  }
</style>
