<template>
  <div>
    <!--需要缓存组件-->
    <keep-alive>
      <!--router-view组件是一个 functional 组件，渲染路径匹配到的视图组件-->
      <router-view v-if="$route.meta.keepAlive" :key="$route.fullPath"
        class="content"></router-view>
    </keep-alive>
    <!--不需要缓存组件-->
    <router-view v-if="!$route.meta.keepAlive" :key="$route.fullPath"
      class="content"></router-view>
  </div>
</template>

<script>
export default {
  name: 'CacheView',
  created () {
    // 关闭标签触发,删掉相应的缓存
    this.$bus.$on('removeCache', keys => {
      this.removeCache(keys)
    })
  },
  methods: {
    /**
     * 获取有keep-alive子节点的Vnode
     * @returns {boolean|VNode | boolean}
     */
    getVnode () {
      // 判断子集非空
      if (this.$children.length === 0) return false
      let vnode
      for (let i = 0; i < this.$children.length; i++) {
        const child = this.$children[i]
        // 如果data中有key则代表找到了keep-alive下面的子集，这个key就是router-view上的key
        if (child.$vnode.data.key) {
          vnode = child.$vnode
          break
        }
      }
      return vnode || false
    },
    /**
     * 移除keep-alive缓存
     * @param keys
     * @returns {boolean}
     */
    removeCache (keys = []) {
      const vnode = this.getVnode()
      if (!vnode) return false
      const componentInstance = vnode.parent?.componentInstance || {}
      if (!componentInstance.cache) return false
      Object.keys(componentInstance.cache).forEach((key, i) => {
        if (keys.includes(key)) {
          // 1 销毁实例
          if (componentInstance.cache[key]?.componentInstance) {
            componentInstance.cache[key].componentInstance.$destroy()
          }
          // 2 删除缓存
          delete componentInstance.cache[key]
          // 3 移除key中对应的key
          componentInstance.keys.splice(i, 1)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  height: 100%;
}
</style>
