<template >
  <a-layout-sider v-if="menuList.length>0" theme="light">
    <div class="top-logo">
      <img :src="titleLogo"  />
    </div>

    <ax-menu :menuList="menuList" :defaultOpenKeys="defaultOpenKeys" @click="clickMenu" />
  </a-layout-sider>
</template>

<script>
import { mapGetters } from 'vuex'
import titleLogo from '@/assets/logo/logo.png'
export default {
  name: 'LayoutSider',
  data () {
    return {
      titleLogo,
      // defaultOpenKeys: ['/enterpriseService/serviceManagement','/enterpriseService/announcementList/announcementList','/system/user']
      defaultOpenKeys: []
    }
  },
  computed: {
    ...mapGetters({
      menuList: 'app/menuList'
    })
  },
  methods: {
    clickMenu ({ key }) {
      this.$router.push({ path: key })
    },
    handlePath () {
      // this.$router.push({
      //   path: '/visualization/index'
      // })
      const { href } = this.$router.resolve({
        path: '/visualization/index'
      })
      window.open(href, '_blank')
    }
  }
}
</script>

<style lang="less" scoped>
@deep: ~'::v-deep';
.ant-layout-sider {
  overflow: hidden;
  @{deep} .ant-layout-sider-children {
    overflow-y: scroll;
    margin-right: -18px;
    .ant-menu {
      height: calc(100% - 60px);
      overflow-y: auto;
      overflow-x: hidden;
      .ant-menu-submenu-selected {
        color: #6db3fd !important;
      }
      .ant-menu-item-selected {
        background-color: #f5f7fb !important;
        color: #6db3fd !important;
        border-right: 3px solid #6db3fd !important;
      }
      .ant-menu-submenu-active,
      .ant-menu-item-active {
        color: #6db3fd !important;
      }
      .ant-menu-submenu-active:hover,
      .ant-menu-submenu-title:hover,
      .ant-menu-submenu:hover,
      .ant-menu-submenu-arrow:hover,
      .ant-menu-item-active:hover {
        color: #6db3fd !important;
      }
      .ant-menu-item::after {
        border-right: 3px solid #6db3fd !important;
      }
      .ant-menu-item .anticon,
      .ant-menu-submenu-title .anticon {
        margin-right: 0 !important;
      }
    }
    .ant-menu::-webkit-scrollbar {
      display: none; /* Chrome Safari */
    }
  }
}
.top-logo {
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  img {
    cursor: pointer;
  }
}
</style>
