<template>
  <a-modal
    v-bind="modalPropsData"
    :visible="visible"
    @cancel="cancel"
    @ok="ok"
  >
    <div
      slot="title"
      class="modal-title-wrapper"
    >
      <template v-if="showProgress">
        <a-progress
          :percent="uploadPercent"
          :width="40"
          type="circle"
        />
        <div class="notice">
          正在上传，请耐心等候...
        </div>
      </template>

      <template v-else>
        <a-upload
          v-bind="uploadPropsData"
          @change="onFileChange"
        >
          <ax-button v-bind="uploadPropsData" />
        </a-upload>
        <div
          v-if="uploadPropsData.showAccept"
          class="notice"
        >
          请上传"<span>{{uploadPropsData.accept}}</span>"类型的文件
        </div>
      </template>
    </div>

    <ax-table
      v-bind="tablePropsData"
      ref="table"
      @data-source-loaded="dataSourceLoaded"
      @pop-cancel="popCancel"
      @pop-confirm="popConfirm"
      @action-column-click="actionColumnClick"
    />
  </a-modal>
</template>

<script>
const tableHeader = [
  { dataIndex: 'userName', title: '文件名', ellipsis: true, width: 400 },
  { dataIndex: 'upUserName', title: '上传人' },
  { dataIndex: 'updateTime', title: '上传时间', width: 180 }
]
const actionCol = {
  width: 160,
  actions: [
    {
      text: '下载',
      name: 'downloadFile'
    },
    {
      text: '删除',
      name: 'deleteFile',
      type: '#f50'
    }
  ]
}

/**
* 关于on-off组件的说明
* @displayName 附件上传
*/
export default {
  name: 'AxUploadModal',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    modalProps: {
      type: Object,
      default: () => ({})
    },
    uploadProps: {
      type: Object,
      default: () => ({})
    },
    tableProps: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      showProgress: false,
      uploadPercent: 0
    }
  },
  computed: {
    modalPropsData () { // 弹窗的属性
      const { modalProps } = this.$ax.config.uploadModal
      return {
        title: undefined,
        footer: null,
        width: 1080,
        bodyStyle: {
          height: '550px',
          overflow: 'auto'
        },
        ...modalProps,
        ...this.modalProps
      }
    },
    uploadPropsData () { // upload的属性
      const { uploadProps } = this.$ax.config.uploadModal
      return {
        text: '上传',
        accept: '.jpg, png',
        showUploadList: false,
        showAccept: true,
        ...uploadProps,
        ...this.uploadProps
      }
    },
    tablePropsData () { // table的属性
      const { tableProps } = this.$ax.config.uploadModal
      const {
        columns = tableHeader,
        showOrder = 'accumulation',
        actionColumn = actionCol,
        tableSettings
      } = { ...tableProps, ...this.tableProps }
      return {
        showSearch: false,
        dataSourceApi: undefined,
        dataSourceParams: this.uploadPropsData.data,
        dataSourceLoaded: ({ dataSource, response, uploadModalIns }) => { },
        popConfirm: ({ record, btn }) => { },
        popCancel: ({ record, btn }) => { },
        ...tableProps,
        ...this.tableProps,
        columns: this.$ax.tools.initColumn(columns, showOrder, actionColumn, tableSettings)
      }
    }
  },
  methods: {
    /**
     * table数据加载完的回调
     * @param args
     */
    dataSourceLoaded ({ dataSource, response }) {
      this.tablePropsData.dataSourceLoaded({ dataSource, response, uploadModalIns: this })
    },
    /**
     * 确认气泡点击取消的回调
     * @param record
     * @param btn
     */
    popCancel ({ record, btn }) {
      this.tablePropsData.popCancel({ record, btn })
    },
    /**
     * 确认气泡点击确认的回调
     * @param record
     * @param btn
     */
    popConfirm ({ record, btn }) {
      this.tablePropsData.popConfirm({ record, btn })
    },
    /**
     * 点击操作列时触发
     * @param record
     * @param btn
     */
    actionColumnClick ({ record, btn }) {
      const params = { record, btn, uploadModalIns: this, tableRef: this.$refs.table }
      if (typeof btn.fn === 'function') btn.fn(params)
      else if (typeof this.tablePropsData[btn.name] === 'function') {
        this.tablePropsData[btn.name](params)
      }
    },
    /**
     * 上传文件改变时的状态
     * @param file 当前操作的文件对象
     */
    onFileChange ({ file }) {
      if (file.status !== undefined) this.showProgress = true
      this.uploadPercent = parseInt(file.percent)
      if (file.response) {
        const { status, message } = file.response
        this.showProgress = false
        if (status !== 200) return this.$message.error(message || '上传失败')
        this.$refs.table.getDataSource(this.tablePropsData.dataSourceParams)
      } else {
        this.showProgress = false
      }
    },
    ok () {
      this.$emit('update:visible', false)
      this.$emit('confirm')
      this.$destroy()
    },
    cancel () {
      this.$emit('update:visible', false)
      this.$emit('cancel')
      this.$destroy()
    }
  }
}
</script>
