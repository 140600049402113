<template>
  <div class="tags-nav">
    <context :visible.sync="visible" @select="onContextSelect" />
    <a-tabs :activeKey="activeTag" :hide-add="true" type="editable-card"
      @change="onTabChange" @edit="onTabRemove"
      @contextmenu.prevent.native="onContextmenu">
      <a-tab-pane v-for="tag in tagList" :key="tag.fullPath"
        :closable="!tag.fixedInTag && tag.title !== '首页'">
        <span slot="tab" :tagKey="tag.fullPath">
          {{tag.title}}
        </span>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import beforeClose from '@/router/beford-close'
import context from './context'
const getTagKey = (target, depth) => {
  depth = depth || 0
  if (depth > 2) return null
  let tagKey = target.getAttribute('tagKey')
  tagKey = tagKey || (target.previousElementSibling ? target.previousElementSibling.getAttribute('tagKey') : null)
  return tagKey || (target.firstElementChild ? getTagKey(target.firstElementChild, ++depth) : null)
}
export default {
  name: 'TagsNav',
  components: { context },
  data () {
    return {
      visible: false,
      tagKey: null,
      activeTag: undefined,
      maxTagCount: 100
    }
  },
  computed: {
    ...mapGetters('app', [
      'tagList'
    ])
  },
  watch: {
    $route (route) {
      this.updateTagList(route)
    },
    activeTag (fullPath) {
      this.$router.push(fullPath)
    }
  },
  created () {
    this.updateTagList(this.$route)
    // 用户通过调用js关闭tag页签
    this.$ax.$on('closeTag', (targetKey, nextRoute) => {
      this.closeTag(targetKey, nextRoute)
    })
    // 用户通过调用js修改tag页签
    this.$ax.$on('updateTagInfo', async (tagInfo = {}) => {
      const targetKey = tagInfo.route?.fullPath
      const tagIndex = this.tagList.findIndex(item => item.fullPath === targetKey)
      // 如果页签不在页签列表里面
      if (tagIndex === -1) await this.updateTagList(tagInfo.route)
      const tagList = await this.tagList.map(item => {
        if (item.fullPath === targetKey) {
          delete tagInfo.route
          return { ...item, ...tagInfo }
        } else return item
      })
      this.setTagList(tagList)
    })
  },
  methods: {
    ...mapMutations('app', [
      'setTagList'
    ]),
    /**
     * 切换页签
     * @param activeTag
     */
    onTabChange (activeTag) {
      this.activeTag = activeTag
    },
    /**
     * 移除页签
     * @param targetKey
     * @returns {MessageType}
     */
    onTabRemove (targetKey) {
      if (this.tagList.length === 1) {
        return this.$message.warning('这是最后一页，不能再关闭了啦')
      }
      const tagInfo = this.tagList.find(tag => tag.fullPath === targetKey)
      if (tagInfo.beforeCloseName in beforeClose) {
        new Promise(beforeClose[tagInfo.beforeCloseName]).then((res = {}) => {
          const { close, nextRoute } = res
          if (close) this.closeTag(targetKey, nextRoute)
        })
      } else {
        this.closeTag(targetKey)
      }
    },
    /**
     * 关闭页签
     * @param targetKey
     * @param nextRoute
     */
    closeTag (targetKey, nextRoute) {
      this.$bus.$emit('removeCache', [targetKey])
      // 当前要关闭的页签的索引
      let tagIndex = this.tagList.findIndex(tag => tag.fullPath === targetKey)
      // 新页签列表
      const newTagList = this.tagList.filter(tag => tag.fullPath !== targetKey)
      // 设置新的页签列表
      this.setTagList(newTagList)
      // 如果有指定的下一个跳转的路由
      if (nextRoute) this.$router.push(nextRoute)
      // 如果没有设置指定的下一个路由，且关闭的是当前页签，则设置新的高亮页签
      else if (this.activeTag === targetKey) {
        // 如果关闭的是最后一个页签，则高亮前一个页签
        if (tagIndex === this.tagList.length) tagIndex = this.tagList.length - 1
        this.activeTag = this.tagList[tagIndex].fullPath
      }
    },
    /**
     * 更新tag页签
     * @param route
     */
    updateTagList (route) {
      const { meta, fullPath } = route
      const tagInfo = { fullPath, ...meta }
      const tagIndex = this.tagList.findIndex(tag => tag.fullPath === tagInfo.fullPath)
      // 页签不在原先的tagList里面
      if (tagIndex === -1) {
        // 控制页签的数量
        if (this.tagList.length >= this.maxTagCount) {
          const index = this.tagList.findIndex(item => !item.fixedInTag)
          this.tagList.splice(index, 1)
        }
        this.setTagList([...this.tagList, tagInfo])
      }
      this.activeTag = tagInfo.fullPath
    },
    /**
     * 右键获取dom
     * @param e
     */
    onContextmenu (e) {
      this.tagKey = getTagKey(e.target)
      if (this.tagKey) this.visible = true
    },
    /**
     * 移除页签时触发
     * @param key
     */
    onContextSelect (key) {
      const tagIndex = this.tagList.findIndex(tag => tag.fullPath === this.tagKey)
      let newTagList = []
      let arrs = []
      switch (key) {
        case 'closeLeft':
          newTagList = this.tagList.slice(tagIndex)
          arrs = newTagList.filter(item => item.fullPath === '/')
          if (arrs.length < 1) {
            newTagList.unshift({
              fullPath: '/', icon: 'icon-home', title: '首页', topPath: '/'
            })
          }
          break
        case 'closeRight':
          newTagList = this.tagList.slice(0, tagIndex + 1)
          arrs = newTagList.filter(item => item.fullPath === '/')
          if (arrs.length < 1) {
            newTagList.unshift({
              fullPath: '/', icon: 'icon-home', title: '首页', topPath: '/'
            })
          }
          break
        case 'closeOther':
          newTagList = this.tagList.slice(tagIndex, tagIndex + 1)
          arrs = newTagList.filter(item => item.fullPath === '/')
          if (arrs.length < 1) {
            newTagList.unshift({
              fullPath: '/', icon: 'icon-home', title: '首页', topPath: '/'
            })
          }
          break
      }
      this.tagList.forEach(tag => {
        const { fixedInTag, fullPath } = tag
        const fixedTagIndex = newTagList.findIndex(item => item.fullPath === fullPath)
        if (fixedInTag && fixedTagIndex === -1) {
          newTagList.unshift(tag)
        }
      })
      this.setTagList(newTagList)
      if (this.tagList.findIndex(tag => tag.fullPath === this.activeTag) === -1) this.activeTag = this.tagKey
    }
  }
}
</script>

<style lang="less" scoped>
.tags-nav {
  /deep/.ant-tabs-bar {
    margin: 0;
    .ant-tabs-nav-container {
      .ant-tabs-nav-wrap {
        .ant-tabs-nav-scroll {
          .ant-tabs-nav {
            .ant-tabs-tab {
              border-radius: 0;
            }
            .ant-tabs-tab-active {
              color: #3753db;
            }
            .ant-tabs-tab:hover {
              color: #3753db;
            }
          }
        }
      }
    }
  }
}
</style>
