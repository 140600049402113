<template>
  <div class="wrapper">
    <Navbaar />
    <!-- <Footer /> -->
    <AppMain />
  </div>
</template>

<script>
import Navbaar from './components/Navbaar.vue'
import AppMain from './components/AppMain.vue'
// import Footer from './components/Sidebar/footer.vue'

export default {
  props: {},
  components: {
    Navbaar,
    AppMain
    // Footer
  },
  data () {
    return {

    }
  },
  computed: {},
  watch: {},
  methods: {

  },
  created () { }
}
</script>
<style lang="less" scoped>
.wrapper {
  overflow: hidden;
}
</style>
