import Vue from 'vue'
import TotalCount from './TotalCount'
import TextCenter from './TextCenter'
import CopyAbleTable from './CopyAbleTable'

const component = [TotalCount, TextCenter, CopyAbleTable]

component.forEach(component => {
  Vue.component(component.name, component)
})
