<template>
  <a-layout-header class="page-header">
    <!-- 右侧操作区 -->
    <ax-icon
      type="icon-tuichudenglu"
      size="20"
      class="logout-icon"
    />
    <headerRight></headerRight>
  </a-layout-header>
</template>

<script>
import headerRight from './header-right-info/index.vue'
export default {
  name: 'LayoutHeader',
  components: { headerRight }
}
</script>
<style lang="less" scoped>
  .page-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background:white;
    color: black;
    .logo{
      flex: none;
      font-size: 30px;
      color: rgb(71,179,143);
      width: 200px;
    }
    .menu-wrapper{
      flex: 1;
      width: calc(100% - 400px);
    }
    .logout-icon{
      flex: none;
      width: 200px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      cursor: pointer;
    }
  }
.top-logo {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
</style>
