import { tools } from 'antdv-libs'
import { getDataSource, getTableLabels } from './utils'
import config from './config'

/**
 * 掉接口获取表头数据并对数据做一个的处理
 * @param tableName 后台对应的表名
 * @param showOrder 是否展示序号 可选值：false不展示，true展示，每一页的序号都是从1开始，accumulation累计序号（带分页生效）
 * @param actionColumn 是否展示操作列，可选值：false不展示， Object操作列的数据，包含列宽和列的操作按钮
 * @param args
 * @returns {Promise<*>}
 */
const getTableColumns = async (tableName, showOrder = true, actionColumn = false, args) => {
  const dynamicFilters = {}
  const columns = await getTableLabels(tableName, 2)
  const tempColumns = await Promise.all(columns.map(async item => {
    const { colDataSource, colName, colLabel, ellipsis, width } = item
    const options = await getDataSource(colDataSource)
    // 合并全局过滤器
    if (options.length) {
      dynamicFilters[`table_${colName}`] = (value, args) => {
        return args.column?.options?.find(item => item.value === value)?.label
      }
    }
    return {
      dataIndex: colName,
      title: colLabel,
      ...item,
      options,
      filter: `table_${colName}`,
      ellipsis: ellipsis === 'Y',
      width: Number(width) || 140
    }
  }))
  Object.assign(config.dynamicFilters, dynamicFilters)
  return tools.initColumn(tempColumns, showOrder, actionColumn, args)
}

export {
  getTableColumns
}
