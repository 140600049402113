import { post, get } from './request'

/**
 * 根据tableName获取获取form的label或table的tableHeader
 * @param tableName 表名
 * @param type 1取form的数据，2取table的数据
 * @param systemName 系统名
 * @returns {Promise<*>}
 */
const getTableLabels = async (tableName, type = 1, systemName) => {
  const response = await post('/table-label/list', { tableName, type }, systemName)
  return response.data
}

/**
 * 获取taskPara
 * @param taskToDo
 * @param systemName
 * @returns {Promise<*>}
 */
const getTaskPara = async (taskToDo, systemName) => {
  const response = await post(`/task-para/taskpara-tasktodo/${taskToDo}`, {}, systemName)
  return response.data
}

/**
 * 获取数据源
 * @param args
 * @param systemName
 * @returns {[]}
 */
const getDataSource = async (args, systemName) => {
  if (!args) return []
  const key = args.substring(0, args.indexOf(':'))
  const value = args.substring(args.indexOf(':') + 1, args.length)
  const opts = []
  let api, params, resultKeys
  switch (key) {
    case 'O':
      api = '/portal/subdepts/'
      params = {}
      resultKeys = { label: 'departName', value: 'id' }
      break
    case 'N':
      api = `/dic/getDictItems/${value}`
      params = {}
      resultKeys = { label: 'label', value: 'value' }
      break
    case 'F':
      api = '/report-flag/datakind/'
      params = { dataKind: value, isValid: 'Y', isValidSelect: 'Y' }
      resultKeys = { label: 'dataDesc', value: 'dataCode' }
      break
    case 'D':
      api = '/dic/reportno'
      params = { dicKind: value, isValid: 'Y', isValidSelect: 'Y' }
      resultKeys = { label: 'dicDesc', value: 'dicCode' }
      break
    case 'C': // 页面自定义数据源
      return value
  }
  const res = await post(api, params, systemName)
  res.data.forEach(item => {
    opts.push({
      label: item[resultKeys.label],
      value: item[resultKeys.value]
    })
  })
  return opts
}

// 获取字典值
const getByDicKind = async (dicKind) => {
  const res = await get('/dict-data/getByDicKind', { dicKind })
  if (res.status === 200) {
    return res.data.map(item => (
      {
        label: item.dicDesc,
        value: item.dicCode
      }
    ))
  }
  return []
}

// 获取科室
const getSectList = async (dicKind) => {
  const res = await get('/dept/sectList')
  if (res.status === 200) {
    return res.data.map(item => (
      {
        label: item.deptName,
        value: item.id
      }
    ))
  }
  return []
}

export {
  getTableLabels,
  getTaskPara,
  getDataSource,
  getByDicKind,
  getSectList
}
