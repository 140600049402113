<template>
  <a-menu
    v-show="visible"
    :style="{ left: `${contextLeft}px` }"
    class="tags-nav-context"
    @click="handleClick"
  >
    <a-menu-item v-for="item in itemList" :key="item.key">
      <ax-icon :type="item.icon" size="20"/>
      {{item.text}}
    </a-menu-item>
  </a-menu>
</template>

<script>
export default {
  name: 'Context',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      itemList: [
        { key: 'closeLeft', icon: 'icon-zuojiantou', text: '关闭左侧' },
        { key: 'closeRight', icon: 'icon-youjiantou', text: '关闭右侧' },
        { key: 'closeOther', icon: 'icon-chacha', text: '关闭其它' }
      ],
      contextLeft: 0
    }
  },
  created () {
    window.addEventListener('mousedown', e => this.toggleVisible(e))
    window.addEventListener('contextmenu', e => this.setPosition(e))
  },
  methods: {
    toggleVisible (e) {
      if (e.target.getAttribute('role') !== 'menuitem') this.$emit('update:visible', false)
    },
    setPosition (e) {
      this.contextLeft = e.clientX
    },
    handleClick ({ key }) {
      this.$emit('select', key)
      this.$emit('update:visible', false)
    }
  }
}
</script>

<style lang="less" scoped>
  .tags-nav-context{
    position: fixed;
    top: 106px;
    z-index: 999;
    border-radius: 4px;
    box-shadow: 2px 2px 10px #aaaaaa!important;
  }
</style>
