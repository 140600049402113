import * as request from 'common/request'

export default {

  getTableHeader: args => request.get('/mock-data/table-header.json', args),
  // 获取附件列表
  getTableData: args => request.get('/file/getByBiz', args),
  // 退出登录
  logout: args => request.post('/auth/logout', args)
}
