import { tools } from 'antdv-libs'
import cookieUtil from './cookie-util'
import { get } from './request'
import { Modal, message } from 'ant-design-vue'

/**
 * 获取附件
 * @param data 请求接口的入参
 * @param callback 请求完成后的回调，会在组件内部执行
 * @param getFileAPi 请求数据的接口
 * @returns {Promise<void>}
 */
const getFileList = async (data, callback = () => { }, getFileAPi = args => get(`${process.env.VUE_APP_BASE_URL}/file/getPageByBiz`, args)) => {
  const res = await getFileAPi(data)
  const fileList = res.data.records
  const fileCount = res.data.records.length
  callback(fileCount, fileList)
}
/**
 * 根据后端返回的图片信息，处理以后回显到页面
 * @param data
 * @returns {*}
 */
const getImgList = data => {
  const dataArr = tools.formatValue(data)
  return dataArr.map(item => {
    return {
      ...item,
      src: `${process.env.VUE_APP_BASE_URL}/indsafety/attach-file/pic-show/${item.id}/2`,
      dataSource: `${process.env.VUE_APP_BASE_URL}/indsafety/attach-file/pic-show/${item.id}/2`,
      id: item.id
    }
  }).filter(item => item)
}

export default {
  homePath: '/',
  getFileList,
  getImgList,
  dynamicFilters: {
    sex: (value) => {
      return value === 'female' ? '男' : '女'
    },
    status: (value) => {
      return value === 'close' ? '关闭' : '开启'
    }
  },
  uploadModal: {
    modalProps: {},
    uploadProps: {
      accept: '.jpg,.png,.jpeg,.word,.bmp,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.swf,.txt,.gif,.pdf',
      action: `${process.env.VUE_APP_BASE_URL}/file/upload/`,
      headers: { Authorization: cookieUtil.getToken() },
      deleteFileApi: args => get('/file/del/' + args.id),
      data: {
        fileType: 1,
        reportNo: '12'
      }
    },
    tableProps: {
      dataSourceApi: args => get('/file/getPageByBiz', args),
      previewFile: ({ record }) => message.success('玩命开发中，敬请期待...！'),
      downloadFile: ({ record }) => window.open(`${process.env.VUE_APP_BASE_URL}/file/download/?id=${record.id}`),
      deleteFile: ({ record, uploadModalIns, tableRef }) => Modal.confirm({
        title: '系统提示',
        content: '确定删除该附件吗？',
        onOk: () => {
          get(`/file/del/?id=${record.id}`)
            .then(res => {
              const { dataSourceParams } = uploadModalIns.tablePropsData
              tableRef.getDataSource(dataSourceParams)
              message.success('删除成功！')
            })
            .catch(e => { console.log(e) })
        }
      }),
      columns: [
        { dataIndex: 'fileName', title: '文件名', ellipsis: true, width: 400 },
        { dataIndex: 'createBy', title: '上传人' },
        { dataIndex: 'createTime', title: '上传时间', width: 180 }
      ],
      actionColumn: {
        width: 100,
        actions: [{
          text: '下载',
          name: 'downloadFile',
          type: '#3853db',
          link: true
        }, {
          text: '删除',
          name: 'deleteFile',
          type: '#f50',
          link: true
        }]
      }
    }
  },
  tableProps: {
    pagination: {
      pageSize: 10
    }
  }
}
