import Vue from 'vue'
import { install } from './main'
import axUploadModal from './ax-upload-modal'
import TotalCount from './TotalCount'

// import ProgressPage from './progress-page'

export * from '../common/utils'
const commandComponents = [TotalCount]

commandComponents.forEach(component => {
  Vue.use(component)
})
export {
  axUploadModal
}

export default {
  axUploadModal: axUploadModal.instance,
  install (Vue, options = {}) {
    install(Vue, options)
    // Vue.use(ProgressPage)
    Vue.prototype.$uploaderModala = axUploadModal.instance
  }
}
