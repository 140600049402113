import Cookies from 'js-cookie'
const prefix = process.env.VUE_APP_PREFIX ? `${process.env.VUE_APP_PREFIX}-` : ''

export default {
  setToken: (value, key = 'Token') => {
    Cookies.set(`${prefix}${key}`, value)
  },
  getToken: (key = 'Token') => {
    return Cookies.get(`${prefix}${key}`)
  },
  removeToken: (key = 'Token') => {
    Cookies.remove(`${prefix}${key}`)
  },
  setToCookie: (key, value) => {
    Cookies.set(`${prefix}${key}`, value)
  },
  getFromCookie: key => {
    return Cookies.get(`${prefix}${key}`)
  },
  removeFromCookie: key => {
    Cookies.remove(`${prefix}${key}`)
  }
}
