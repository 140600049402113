<template>
  <div class="topbox">
    <p class="title">长江新螺段白鱀豚国家级自然保护区</p>
    <!-- <div class="loginbox" v-if="anvshow">
      <img src="../../assets/homeimg/logo.png" alt="" />
    </div> -->
    <!-- <div class="timebox" v-if="anvshow">
      <p>{{date.year}}年{{date.month}}月{{date.day}}日</p>
      <p>周{{date.week}}</p>
      <p>{{nowDate}}</p>
    </div> -->
  </div>
</template>

<script>
import { formatDate } from '../../frontdesk/visualization/util'
import { mapGetters } from 'vuex'
export default {
  props: {},
  components: {
  },
  data () {
    return {
      footerlist: ['图列信息', '监控设备', '巡护管理', '环境监测', '预警统计', '宣传片'],
      date: {
        year: '',
        month: '',
        day: '',
        week: ''
      },
      anvshow: true,
      nowDate: ''
    }
  },
  computed: {
    ...mapGetters('zsqy', ['isshow'])

  },
  watch: {
    // isshow: {
    //   handler (val, old) {
    //     this.anvshow = val
    //   },
    //   immediate: true,
    //   deep: true
    // }
  },
  mounted () {
    // this.$nextTick(function () {
    //   setInterval(this.pagetiem, 1000)
    // })
  },
  methods: {
    footerbtn (index) {
      this.footerAction = index
    },
    pagetiem () {
      // 查询日期
      const date = new Date()
      this.date.year = date.getFullYear()
      this.date.month = date.getMonth() + 1
      this.date.day = date.getDate()
      const arr = ['一', '二', '三', '四', '五', '六', '日']
      this.date.week = arr[date.getDay() - 1]
      this.nowDate = formatDate(new Date().getTime())
    }
  },
  created () {
    this.pagetiem()
  }
}
</script>
<style lang="less" scoped>
.topbox {
  width: 100%;
  height: 85px;
  position: relative;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  background: url('../../assets/homeimg/top.png') no-repeat;
  background-size: 100% 100%;
  .title {
    font-size: 34px;
    font-family: SourceHanSansCN;
    font-weight: bold;
    color: #e6fcff;
    text-align: center;
    margin-left: 2%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
  .loginbox {
    position: absolute;
    top: 27%;
    left: 7%;
    height: 50px;
    img {
      width: 103px;
      height: 34px;
      margin-left: 20%;
    }
  }
  .timebox {
    position: absolute;
    top: 27%;
    right: 8%;
    height: 50px;
    width: 15%;
    display: flex;
    font-size: 20px;
    background: linear-gradient(88deg, #374862 0%, rgba(230, 252, 255, 0) 100%);
    border-radius: 10px;
    font-family: SourceHanSansCN;
    font-weight: bold;
    // color: #000;
    color: #e6fcff;
    p {
      margin-left: 15px;
      line-height: 50px;
    }
  }
}
</style>
