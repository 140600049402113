import exceptions from '@/pages/exceptions'
export default [
  {
    path: '*',
    name: '*',
    meta: {
      title: '404',
      exception: '404',
      hideInMenu: true
    },
    component: exceptions
  },
  {
    path: '/page500',
    name: 'page500',
    meta: {
      title: 'page500',
      exception: '500',
      hideInMenu: true
    },
    component: exceptions
  },
  {
    path: '/page403',
    name: 'page403',
    meta: {
      title: 'page403',
      exception: '403',
      hideInMenu: true
    },
    component: exceptions
  }
]
