const zsqy = {
  namespaced: true,
  state: {
    isshow: false, // 头部消失隐藏
    footershow: false, // 底部消失隐藏
    WaterQuality: null, // 水质通用数据
    meteorological: null, // 气象通用数据
    hydrological: null, // 水文通用数据
    tokens: null, // 获取token
    legenddata: null,
    pagedoobj: {}
  },
  getters: {
    isshow: state => state.isshow,
    footershow: state => state.footershow,
    WaterQuality: state => state.WaterQuality,
    meteorological: state => state.meteorological,
    hydrological: state => state.hydrological,
    tokens: state => state.tokens,
    legenddata: state => state.legenddata,
    pagedoobj: state => state.pagedoobj
  },
  mutations: {
    setisshow (state, isshow) {
      state.isshow = isshow
    },
    setfootershow (state, footershow) {
      state.footershow = footershow
    },
    setWaterQuality (state, WaterQuality) {
      state.WaterQuality = WaterQuality
    },
    setmeteorological (state, meteorological) {
      state.meteorological = meteorological
    },
    sethydrological (state, hydrological) {
      state.hydrological = hydrological
    },
    settokens (state, tokens) {
      state.tokens = tokens
    },
    setlegenddata (state, legenddata) {
      state.legenddata = legenddata
    },
    setOrderobj (state, pagedoobj) {
      state.pagedoobj = pagedoobj
    }

  },
  actions: {

  }
}

export default zsqy
