const prefix = process.env.VUE_APP_PREFIX ? `${process.env.VUE_APP_PREFIX}_` : ''

export default {
  setToStorage: (key, value) => {
    localStorage.setItem(`${prefix}${key}`, JSON.stringify(value))
  },
  getFromStorage: (key) => {
    return JSON.parse(localStorage.getItem(`${prefix}${key}`))
  },
  removeFromStorage: key => {
    localStorage.removeItem(`${prefix}${key}`)
  },
  clearLocalStorage: () => {
    localStorage.clear()
  }
}
