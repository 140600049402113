const project = {
    namespaced: true,
    state: {
        projectinfo: {},
        FileMsg: {}
    },
    getters: {
    },
    mutations: {
        getProjectinfo(state, info) {
            state.projectinfo = info
        },
        getFileMsg(state, info) {
            state.FileMsg = info
        },

    },
    actions: {

    }
}


export default project
