<template>
  <a-layout class="main-view">
    <layoutSider :collapsed="$ax.config.collapsed" :collapsedWidth="0"
      :menuKey="menuKey" />

    <a-layout>
      <layoutHeader @setMenuKey="setMenuKey" />
      <tagsNav v-if="!$route.meta.hideTagNav" />

      <a-layout-content class="layout-content">
        <cahcheView class="content" />
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<script>
import layoutHeader from './components/layout-header'
import layoutSider from './components/layout-sider'
import tagsNav from './components/tags-nav'
import cahcheView from './components/cache-view'
import localStorageUtil from 'common/local-storage-util'
export default {
  name: 'LayoutView',
  components: {
    layoutHeader,
    layoutSider,
    tagsNav,
    cahcheView
  },
  data () {
    return {
      menuKey: '/'
    }
  },
  mounted () {
    this.menuKey = localStorageUtil.getFromStorage('menuKey') ? localStorageUtil.getFromStorage('menuKey')[0] : '/'
  },
  methods: {
    setMenuKey (key) {
      this.menuKey = key
    }
  }
}
</script>

<style lang="less" scoped>
.main-view {
  height: 100%;
  .layout-content {
    padding: 16px;
    overflow: auto;
    .content {
      padding: 12px;
      border-radius: 6px;
      background: #ffffff;
      height: 100%;
        overflow-x: hidden;
  overflow-y: auto;
    }
  }
}
</style>
