import config from 'common/config'
import { get } from 'common/request'
import { getAsyncRoutes } from 'common/tools'
import router from '../../router'
import routes from '../../router/routes'
import cookieUtil from '../../common/cookie-util'
const app = {
  namespaced: true,
  state: {
    userInfo: null,
    tagList: [],
    menuList: [],
    count: {},
    loginStatus: '1'
  },
  getters: {
    userInfo: state => state.userInfo,
    count: state => state.count,
    tagList: state => {
      // 将首页置前
      const homeTagIndex = state.tagList.findIndex(tag => tag.fullPath === config.homePath)
      if (homeTagIndex > -1) {
        const homeTag = state.tagList.splice(homeTagIndex, 1)
        state.tagList.unshift(...homeTag)
      }
      return state.tagList
    },
    menuList: state => state.menuList,
    loginStatus: state => state.loginStatus
  },
  mutations: {
    setUserInfo (state, userInfo) {
      state.userInfo = userInfo || {}
    },
    setCount (state, count) {
      state.count = count
    },
    setTagList (state, tagList) {
      state.tagList = tagList
    },
    setMenuList (state, menuList) {
      state.menuList = menuList || []
    },
    setLoginStatus (state, loginStatus) {
      state.loginStatus = loginStatus || '1'
    }
  },
  actions: {
    async getMenuList ({ commit }) {
      try {
        // 获取菜单
        // ?.?.返回null或者undefined时才取后者
        const menu = (await get('/permission/getMenu'))?.data?.menu
        const menuList = getAsyncRoutes(menu)
        router.addRoutes(menuList)
        router.addRoutes(routes)
        commit('setMenuList', routes.concat(menuList))
        return menuList

      } catch (e) {
        if (e.status === 403) {
          cookieUtil.removeToken()
          router.push({
            path: '@/pages/login'
          })
        }
      }
    },
    async getCount ({ commit }) {
      const count = (await get('/home/messageCount')).data
      commit('setCount', count)
    }
  }
}

export default app
